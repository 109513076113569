<template>
  <tr>
    <td class="checkboxTd" v-if="isRoleAdmin">
      <input v-bind:id="'checkbox-' + item.id"
             class="checkbox"
             type="checkbox"
             @change="countEvent()"
             ref="checkbox"
             :disabled="item.status === 'DELETED'">
    </td>
    <td class="idTd" @click="goToSingleItem(item.id)">{{ item.id }}</td>
    <td class="systemTd" @click="goToSingleItem(item.id)">{{ item.system }}</td>
    <td :class="getManufacturerClass()" @click="goToSingleItem(item.id)"
        v-if="manufacturerId == null">{{ item.manufacturer.name }}</td>
    <td class="mountTypeTd" @click="goToSingleItem(item.id)">{{ item.mountType }}</td>
    <td class="wxdxtTd" @click="goToSingleItem(item.id)" colspan="">
      <span>{{ item.width }}x</span><span>{{ item.depth }}x</span><span>{{ item.thickness }}</span>
    </td>
    <td class="l1xl2Td" @click="goToSingleItem(item.id)" colspan="">
      <span>{{ item.horizontalStep }}x</span><span>{{ item.verticalStep }}</span>
    </td>
    <td class="imageThTd" ref="onHover" @mouseover="getCoordinates" @mouseleave="hover = false">
      <div class="imageDiv" v-if="!item || !(item.imageUrl)">
        <img class="realImg" src="../../../public/no-photo.jpg" v-bind:alt="$t('soloItem.noImageAlt')">
      </div>
      <div class="imageDiv" v-else>
        <img class="realImg" :src="item.imageUrl" v-bind:alt="$t('soloItem.imageAlt')">
      </div>
      <div class="backgroundDiv" v-if="!item || !(item.imageUrl)">
        <img class="realBackgroundImg" src="../../../public/no-photo.jpg" v-bind:alt="$t('soloItem.noImageAlt')">
      </div>
      <div class="backgroundDiv" v-else>
        <img class="realBackgroundImg" :src="item.imageUrl" v-bind:alt="$t('soloItem.imageAlt')">
      </div>
    </td>
    <td class="dateTd" @dblclick="goToSingleItem(item.id)">{{ moment(item.updated).format('DD.MM.YYYY HH:mm') }}</td>
    <td :class="getStatusClass()" @click="goToSingleItem(item.id)" v-if="isRoleAdmin">
      {{ item.status === "ACTIVE" ? this.$t("soloItem.active") : this.$t("soloItem.deleted") }}
    </td>
  </tr>
</template>

<script>
import moment from 'moment';
import SideBarMenu from "../SideBarMenu";

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    manufacturerId: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      hover: false,
      left: 0,
      top: 0,
      isRoleAdmin: false
    };
  },

  computed: {
    calculate() {
      return this.getCoordinates;
    }
  },

  mounted() {
    this.isRoleAdmin = SideBarMenu.methods.isRoleAdmin();
  },

  methods: {
    getCoordinates() {
      this.hover = true;
      this.left = this.$refs.onHover.getBoundingClientRect().left + 35 + 'px';
      this.top = this.$refs.onHover.getBoundingClientRect().top + 'px';
      let style = document.createElement('style');
      style.setAttribute('type', 'text/css');
      document.head.appendChild(style);
      let sheet = style.sheet;
      sheet.insertRule(
          '@keyframes scale{ ' +
          'from {' +
          'top: ' + this.top + ';' +
          'left: ' + this.left + ';' +
          'transform: scale(1.0);' +
          '}' +
          'to { ' +
          'top: 50%;' +
          'left:' + this.left + ';' +
          'transform:scale(6.0);' +
          '}' +
          '}'
      );
    },

    getStatusClass() {
      return (this.item.status === 'DELETED') ? "statusTd deleted" : "statusTd";
    },

    getManufacturerClass() {
       return (this.item.manufacturer.status === 'DELETED') ? "manufacturerTd deleted" : "manufacturerTd";
    },

    goToSingleItem(id) {
      this.$router.push({name: 'itemById', params: {"id": id.toString()}});
    },
    moment,

    countEvent() {
      this.$emit('addRaw')
    },
  }
}

</script>

<style scoped lang="scss">
@import "../../../public/styles/tables.scss";

.imageThTd:hover {
  background: radial-gradient(rgba(0, 0, 0, 0.1), transparent);

  .backgroundDiv {
    display: block;
  }

  .imageDiv {
    pointer-events: none;
    animation: scale 250ms ease-in-out forwards;
    display: block;
    position: fixed;
    background: whitesmoke;

    border-radius: 2px;
  }
}

.statusTd.deleted, .manufacturerTd.deleted {
  color: red;
}

.systemTd, .manufacturerTd, .mountTypeTd {
  overflow-wrap: anywhere;
  min-width: 48px;
}

.wxdxtTd > span, .l1xl2Td > span {
  display: inline-block;
}

.imageThTd {
  min-width: 44px;
}

</style>