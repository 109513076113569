<template>
  <table>
    <thead id="tableHead">
      <tr>
        <th class="checkbox" v-if="isRoleAdmin"><input type="checkbox" id="titleCheckbox" @change="checkAll"></th>

        <th class="id" @click="sortByField('id')">
          <div class="parent">
            <div class="left">{{ $t("itemTable.id") }}</div>
            <div class="right">
              <div class="top"><span class="fa fa-sort-up" :class="sortUp('id')"></span></div>
              <div class="bottom"><span class="fa fa-sort-down" :class="sortDown('id')"></span></div>
            </div>
          </div>
        </th>

        <th class="system" @click="sortByField('system')">
          <div class="parent">
            <div class="left">{{ $t("itemTable.system") }}</div>
            <div class="right">
              <div class="top"><span class="fa fa-sort-up" :class="sortUp('system')"></span></div>
              <div class="bottom"><span class="fa fa-sort-down" :class="sortDown('system')"></span></div>
            </div>
          </div>
        </th>

        <th class="manufacturer" @click="sortByField('manufacturer')" v-if="manufacturerId == null">
          <div class="parent">
            <div class="left">{{ $t("itemTable.manufacturer") }}</div>
            <div class="right">
              <div class="top"><span class="fa fa-sort-up" :class="sortUp('manufacturer')"></span></div>
              <div class="bottom"><span class="fa fa-sort-down" :class="sortDown('manufacturer')"></span></div>
            </div>
          </div>
        </th>

        <th class="mountType" @click="sortByField('mountType')">
          <div class="parent">
            <div class="left">{{ $t("itemTable.mountType") }}</div>
            <div class="right">
              <div class="top"><span class="fa fa-sort-up" :class="sortUp('mountType')"></span></div>
              <div class="bottom"><span class="fa fa-sort-down" :class="sortDown('mountType')"></span></div>
            </div>
          </div>
        </th>

        <th class="wxdxt">
          <div class="parent">
            <div class="left">
              <label
                @click="sortByField('width')"
                :class="isClicked('width') ? 'active' : ''"
              >{{$t("itemTable.width")}}</label>x<label
                @click="sortByField('depth')"
                :class="isClicked('depth') ? 'active' : ''"
              >{{$t("itemTable.depth")}}</label>x<label
                @click="sortByField('thickness')"
                :class="isClicked('thickness') ? 'active' : ''"
              >{{$t("itemTable.thickness")}}</label>
            </div>
            <div class="right">
              <div class="top">
                  <span class="fa fa-sort-up"
                        :class="equalsFields('width')
                        || equalsFields('depth')
                        || equalsFields('thickness') ? (sortAndOrder() ? 'inactive' : 'active') : 'inactive'">
                  </span>
              </div>
              <div class="bottom">
                  <span class="fa fa-sort-down"
                        :class="equalsFields('width')
                        || equalsFields('depth')
                        || equalsFields('thickness') ? (sortAndOrder() ? 'active' : 'inactive') : 'inactive'">
                  </span>
              </div>
            </div>
          </div>
        </th>

        <th class="l1xl2">
          <div class="parent">
            <div class="left">
              <label @click="sortByField('horizontalStep')"
                     :class="isClicked('horizontalStep') ? 'active' : ''"
              >{{ $t("itemTable.horizontalStep") }}</label>x<label @click="sortByField('verticalStep')"
                  :class="isClicked('verticalStep') ? 'active' : ''"
              >{{ $t("itemTable.verticalStep") }}</label>
            </div>
            <div class="right">
              <div class="top">
                  <span class="fa fa-sort-up"
                        :class="equalsFields('verticalStep')
                        || equalsFields('horizontalStep') ? (sortAndOrder() ? 'inactive' : 'active') : 'inactive'">
                  </span>
              </div>
              <div class="bottom">
                  <span class="fa fa-sort-down"
                        :class="equalsFields('verticalStep')
                        || equalsFields('horizontalStep') ? (sortAndOrder() ? 'active' : 'inactive') : 'inactive'">
                  </span>
              </div>
            </div>
          </div>
        </th>

        <th class="imageCol">
          <div class="parent">{{ $t("itemTable.image") }}</div>
        </th>

        <th class="date" @click="sortByField('updated')">
          <div class="parent">
            <div class="left">{{ $t("itemTable.updated") }}</div>
            <div class="right">
              <div class="top"><span class="fa fa-sort-up" :class="sortUp('updated')"></span></div>
              <div class="bottom"><span class="fa fa-sort-down" :class="sortDown('updated')"></span></div>
            </div>
          </div>
        </th>

        <th class="status" v-if="displayStatus()" @click="sortByLocalizedField('status', 'recordStatus')">
          <div class="parent">
            <div class="left">{{ $t("itemTable.status") }}</div>
            <div class="right">
              <div class="top"><span class="fa fa-sort-up" :class="sortUp('status')"></span></div>
              <div class="bottom"><span class="fa fa-sort-down" :class="sortDown('status')"></span></div>
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody id="tbody">
    <SoloItem ref="itemList"
              @addRaw="changeDiv"
              v-for="item of items" :key="item.id"
              v-bind:item="item"
              v-bind:manufacturer-id="manufacturerId"
    />
    </tbody>
  </table>
</template>


<script>
import SoloItem from "./SoloItem";
import App from "../../App";
import $ from "jquery";
import SideBarMenu from "../SideBarMenu";

export default {
  props: {
    items: {
      type: Array
    },

    manufacturerId: {
      type: String,
      required: false
    }
  },

  components: {
    SoloItem: SoloItem
  },

  data() {
    return {
      orderBy: false,
      field: '',
      clicked: false,
      checkedItemsList: [],
      isRoleAdmin: false
    }
  },

  created() {
    this.isRoleAdmin = SideBarMenu.methods.isRoleAdmin();
    if (this.isRoleAdmin === true) {
      this.$parent.$on('removeCheckboxes', this.removeCheckboxes);
    }
  },

  beforeDestroy() {
    if (this.isRoleAdmin === true) {
      this.$parent.$off('removeCheckboxes', this);
    }
  },

  watch: {
    items: function () {
      this.restoreOrders();
    }
  },

  methods: {
    getStoreKey() {
      return (this.manufacturerId > 0 ? "manufacturers." : "") + "items.";
    },

    restoreOrders() {
      const storeKey = this.getStoreKey();
      let field = window.localStorage.getItem(storeKey + "field");
      let order = window.localStorage.getItem(storeKey + "orderBy") === 'true';
      if (field != null) {
        this.field = field
        this.orderBy = order;
        this.sortByField(null);
      }
    },

    sortByLocalizedField(field, localizedPrefix) {
      if (field != null) {
        if (Object.is(this.field, field)) {
          this.orderBy = !this.orderBy;
        } else {
          this.field = field;
          this.orderBy = true;
        }

        const storeKey = this.getStoreKey();
        window.localStorage.setItem(storeKey + "field", this.field)
        window.localStorage.setItem(storeKey + "orderBy", this.orderBy)
      }

      this.items.sort(App.methods.comparator(a =>
          this.$t(localizedPrefix + '.' + a[this.field]),
          this.orderBy
      ));
    },

    sortByField(field) {
      if (field != null) {
        if (Object.is(this.field, field)) {
          this.orderBy = !this.orderBy;
        } else {
          this.field = field;
          this.orderBy = true;
        }

        const storeKey = this.getStoreKey();
        window.localStorage.setItem(storeKey + "field", this.field)
        window.localStorage.setItem(storeKey + "orderBy", this.orderBy)
      }

      if (this.field === 'manufacturer') {
        this.items.sort(App.methods.comparator(a => a.manufacturer.name, this.orderBy));
      } else {
        this.items.sort(App.methods.comparator(a => a[this.field], this.orderBy));
      }
    },

    isClicked(field) {
      return this.field === field;
    },

    sortAndOrder() {
      return this.orderBy;
    },

    equalsFields(field) {
      return this.field === field;
    },

    sortUp(field) {
      return this.equalsFields(field) ? (this.sortAndOrder() ? 'inactive' : 'active') : 'inactive';
    },

    sortDown(field) {
      return this.equalsFields(field) ? (this.sortAndOrder() ? 'active' : 'inactive') : 'inactive';
    },

    displayStatus() {
      if (!SideBarMenu.methods.isRoleAdmin()) {
        $('#important').css('width', '20%');
        $('#id').css('width', '7%')
        $('#status').css('width', '0%');
        return false;
      }
      return true;
    },

    changeDiv() {
      let itemList = this.$refs.itemList;
      this.checkedItemsList = [];
      itemList.forEach(element => {
        if (element.$refs.checkbox.checked) {
          this.checkedItemsList.push(element.item.id);
        }
      });
      if (this.checkedItemsList.length > 0) {
        if (this.checkedItemsList.length === this.items.length) {
          $("#titleCheckbox").prop('checked', true);
        } else $("#titleCheckbox").prop('checked', false);
        this.$emit('setPanelVisible', this.checkedItemsList)
      } else {
        this.$emit('setHeaderVisible')
      }
    },

    checkAll() {
      let itemList = this.$refs.itemList;
      this.checkedItemsList = [];
      if ($("#titleCheckbox").prop('checked')) {
        itemList.forEach(element => {
          if (element.item.status !== "DELETED") {
            element.$refs.checkbox.checked = true;
            this.checkedItemsList.push(element.item.id);
          }
        });
        this.$emit('setPanelVisible', this.checkedItemsList)
      } else {
        itemList.forEach(element => {
          element.$refs.checkbox.checked = false;
        });
        this.$emit('setHeaderVisible')
      }
    },

    removeCheckboxes() {
      let itemList = this.$refs.itemList;
      $("#titleCheckbox").prop('checked', false);
      itemList.forEach(element => {
        element.$refs.checkbox.checked = false;
      });
      this.$emit('setHeaderVisible')
    }
  },
}
</script>


<style scoped lang="scss">
@import "../../../public/styles/tables.scss";

</style>